<template>
  <div class="split-outcome-cell">
    <div v-for="(outcome, index) in outcomes" :key="index" class="outcome-item">
      <StaticCellView :content="outcome.outcome" />
      <div v-if="outcome.evidenceType">
        <q-icon
          class="toggle-icon"
          :name="isContentVisible(index) ? 'expand_less' : 'expand_more'"
          @click="toggleContent(index)"
          aria-hidden="true"
        />
        <div v-if="isContentVisible(index)" class="collapsible-content">
          <StaticCellView
            :content="outcome.evidenceType"
            :class="getEvidenceTypeClass(outcome.evidenceType)"
            @click="handleClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StaticCellView from './GipamStaticCellView.vue'

export default {
  props: {
    outcomes: {
      type: Array,
      required: true,
    },
  },
  components: {
    StaticCellView,
  },
  data() {
    return {
      visibleContent: Array(this.outcomes.length).fill(true), // Initialize as an array of booleans
    }
  },
  methods: {
    handleClick() {
      this.$emit('link-clicked')
    },
    toggleContent(index) {
      // Toggle visibility for the specific outcome
      this.visibleContent[index] = !this.visibleContent[index]
    },
    isContentVisible(index) {
      return this.visibleContent[index]
    },
    getEvidenceTypeClass(evidenceType) {
      switch (evidenceType) {
        case 'Randomized controlled trial (RCT)':
          return 'bg-green'
        case 'Subgroup analysis of RCT data':
        case 'Bayesian network meta-analysis (NMA)':
        case "Standard NMA (e.g. Bucher's adjusted indirect comparison)":
          return 'bg-light-green'
        case 'Patient-level adjustment to external controls (e.g. using propensity scores)':
        case 'Adjustment methods at population level (e.g. MAIC, STC)':
          return 'bg-yellow'
        case 'Naïve/unadjusted comparison':
        case 'Other':
          return 'bg-orange'
        case 'No evidence/ missing data':
          return 'bg-red'
        default:
          return ''
      }
    },
  },
}
</script>

<style scoped>
.split-outcome-cell {
  display: flex;
  flex-direction: column;
}
.outcome-item {
  margin: 2px 0;
  display: flex;
  flex-direction: column; /* Stack outcome and evidence vertically */
  align-items: flex-start; /* Align items to the start */
}
.toggle-icon {
  cursor: pointer;
  margin-left: 8px; /* Space between StaticCellView and icon */
}
.collapsible-content {
  background-color: #f9f9f9; /* Optional background color */
  margin-top: 5px; /* Space above collapsible content */
}

/* Background color classes */
.bg-green {
  background-color: green;
  color: white; /* Optional: improve text visibility */
  padding: 10px;
  border: 1px solid #ccc; /* Optional border */
  margin-top: 5px; /* Space above collapsible content */
}
.bg-light-green {
  background-color: lightgreen;
}
.bg-yellow {
  background-color: yellow;
}
.bg-orange {
  background-color: orange;
  color: white; /* Optional: improve text visibility */
}
.bg-red {
  background-color: red;
  color: white; /* Optional: improve text visibility */
}
</style>
