<template>
  <div>
    <h3>Outcome(s)</h3>

    <div>
      <label>outcome category:</label>
      <p>{{ hardcodedCategory }}</p>
    </div>

    <div>
      <label>Definition of Outcome Measure:</label>
      <p>{{ hardcodedDefinition }}</p>
    </div>

    <div>
      <label>Time of Assessment:</label>
      <p>{{ hardcodedAssessmentTime }}</p>
    </div>

    <div>
      <label>Statistical Measure for Treatment Effect Size:</label>
      <p>{{ hardcodedStatisticalMeasure }}</p>
    </div>

    <!-- Further Explanation Field -->
    <div v-if="showFurtherExplanation">
      <label>Further Explanation:</label>
      <p>{{ hardcodedFurtherExplanation }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OutcomeForm',
  data() {
    return {
      selectedCategory: '',
      selectedOutcome: '',
      showFurtherExplanation: false,
      // Hardcoded values
      hardcodedCategory: 'Mortality',
      hardcodedDefinition: 'This is a dummy definition of the outcome measure.',
      hardcodedAssessmentTime:
        'Assessment was conducted at baseline and 6 months.',
      hardcodedStatisticalMeasure: 'Mean Difference',
      hardcodedFurtherExplanation:
        'This measure indicates the average difference between groups.',
      outcomes: ['Outcome 1', 'Outcome 2', 'Outcome 3'], // Dummy outcomes
    }
  },
  methods: {
    resetFields() {
      this.selectedOutcome = ''
      this.showFurtherExplanation = false
      // You can also reset hardcoded values here if needed
    },
    onOutcomeChange() {
      // Logic to show further explanation if needed
      this.showFurtherExplanation = this.selectedOutcome === 'Outcome 1' // Example condition
    },
  },
}
</script>

<style scoped>
/* Add your styles here */
p {
  margin-top: 5px;
  margin-bottom: 5px;
}
select {
  margin-top: 5px;
}
label {
  margin-right: 10px;
}
</style>
