<template>
  <div class="matrix-container">
    <h5>Results Matrix</h5>
    <GipamTable
      :rows="resultsData"
      :columns="getColumns()"
      :row-types="getRowTypes()"
      :is-mandatory="getIsMandatory()"
      table-title="Results Matrix"
    />
  </div>
</template>

<script>
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'

export default {
  name: 'ResultsMatrix',
  components: {
    GipamTable,
  },
  data() {
    return {
      countries: [
        'France',
        'Germany',
        'Italy',
        'Spain',
        'Poland',
        'Netherlands',
        'Belgium',
        'Austria',
        'Greece',
        'Czech Republic',
        'Portugal',
        'Hungary',
        'Sweden',
        'Finland',
        'Denmark',
        'Ireland',
        'Slovakia',
        'Lithuania',
        'Slovenia',
        'Latvia',
        'Estonia',
        'Cyprus',
        'Malta',
        'Romania',
        'Bulgaria',
        'Croatia',
      ],
      picoData: [
        {
          name: 'PICO 1',
          countries: ['France', 'Germany'],
          evidence: {
            France: 'Randomized controlled trial (RCT)',
            Germany: 'No Evidence/Missing data',
          },
          population: 'Adults',
          intervention: 'Drug A',
          comparator: 'Placebo',
          outcomes: [
            {
              outcome: 'Outcome 1 ganz langes Outcome',
            },
            {
              outcome: 'Outcome 2 genauso langes outcome',
            },
          ],
        },
        {
          name: 'PICO 2',
          countries: ['Italy'],
          evidence: {
            France: 'Randomized controlled trial (RCT)',
            Germany: 'No Evidence/Missing data',
            // Other countries...
          },
          population: 'Children',
          intervention: 'Drug B',
          comparator: 'Standard Care',
          outcomes: [
            {
              outcome: 'Outcome 3',
            },
          ],
          importance: 'Medium',
          evidenceType: ['Randomized controlled trial (RCT)'],
          results: [], // Empty for no results
        },
      ],
    }
  },

  computed: {
    resultsData() {
      return this.picoData.map((pico) => {
        const row = {
          Population: pico.population,
          Intervention: pico.intervention,
          Comparator: pico.comparator,
          Outcomes: pico.outcomes,
        }

        // Add evidence data for each country
        this.countries.forEach((country) => {
          row[country] = pico.evidence[country] || 'No Evidence/Missing data'
        })
        console.log(row)
        return row
      })
    },
  },

  methods: {
    getColumns() {
      const fixedColumns = [
        { label: 'Population', field: 'Population' },
        { label: 'Intervention', field: 'Intervention' },
        { label: 'Comparator', field: 'Comparator' },
        { label: 'Outcome(s)', field: 'Outcomes' },
      ]

      const countryColumns = this.countries.map((country) => ({
        label: country,
        field: country,
      }))

      return [...fixedColumns, ...countryColumns]
    },
    getRowTypes() {
      const rowTypes = {}
      this.resultsData.forEach((row, rowIndex) => {
        rowTypes[rowIndex] = {}

        // Set types for fixed columns
        rowTypes[rowIndex]['Population'] = 'static'
        rowTypes[rowIndex]['Intervention'] = 'static'
        rowTypes[rowIndex]['Comparator'] = 'static'
        rowTypes[rowIndex]['Outcomes'] = 'split'

        // Set types for country-specific columns
        this.countries.forEach((country) => {
          rowTypes[rowIndex][country] = 'static'
        })
      })
      return rowTypes
    },
    getIsMandatory() {
      const rowTypes = {}
      this.resultsData.forEach((row, rowIndex) => {
        rowTypes[rowIndex] = {}
        this.countries.forEach((country) => {
          rowTypes[rowIndex][country] = true
        })
      })
      return rowTypes
    },
    getEvidenceClass(evidenceType) {
      if (!evidenceType) return ''
      switch (evidenceType) {
        case 'Randomized controlled trial (RCT)':
          return 'highlight-green'
        case 'Subgroup analysis of RCT data':
        case 'Bayesian network meta-analysis (NMA)':
        case 'Standard NMA':
          return 'highlight-light-green'
        case 'Patient-level adjustment to external controls':
        case 'Population-level adjustment methods':
          return 'highlight-yellow'
        case 'Naïve/unadjusted comparison':
          return 'highlight-orange'
        case 'Other':
        case 'No Evidence/Missing data':
          return 'highlight-red'
        default:
          return ''
      }
    },
  },
}
</script>

<style scoped>
.matrix-container {
  margin-bottom: 20px;
}
</style>
