<template>
  <div class="basic-data-form q-pa-md">
    <q-form>
      <div class="form-group flex-container">
        <div class="input-container">
          <label>Name of asset</label>
          <q-input
            filled
            v-model="assetName"
            :disabled="isAssetCreated"
            id="assetName"
          />
        </div>

        <div class="input-container">
          <label>Projected date of submission regulatory dossier to EMA</label>
          <q-input
            filled
            v-model="dateSubmission"
            type="month"
            id="dateSubmission"
          />
        </div>
      </div>

      <div class="form-group">
        <label>Responsible for PICO Planning</label>
        <div class="responsible-person-container">
          <div
            v-for="(responsible, index) in responsiblePersons"
            :key="index"
            class="responsible-person q-mb-md"
          >
            <q-select
              v-model="responsible.user"
              :options="
                userNames.map((user) => ({
                  label: `${user.name} ${user.vorname}`,
                  value: user,
                }))
              "
              label="Select User"
              class="select-user"
              @update:modelValue="(value) => handleUserSelection(value, index)"
              :emit-value="true"
              map-options
            />
            <div class="function-label">{{ responsible.userRole }}</div>
            <div class="button-group">
              <q-btn
                v-if="responsible.userRole !== 'Main Responsible'"
                @click="setMainResponsible(index)"
                label="Set as Main Responsible"
                color="primary"
                class="set-main-btn"
              />
              <q-btn
                flat
                round
                color="red"
                icon="close"
                size="sm"
                @click="removeResponsiblePerson(index)"
                class="remove-responsible-btn"
              />
            </div>
          </div>

          <!-- Add class to position button -->
          <q-btn
            @click="addResponsiblePerson"
            label="Add Responsible Person"
            color="primary"
            class="add-responsible-btn float-left"
          />
        </div>
      </div>

      <label>Orphan drug?</label>
      <DropdownCellView
        :modelValue="orphanDrugContent"
        :options="yesNoOptions"
        :isMandatory="false"
        @update:modelValue="handleOrphanDrugModelValueUpdate"
        class="neumorphic-dropdown"
      />

      <div class="form-actions">
        <q-btn @click="confirmBasicData" label="Confirm" color="primary" />
      </div>
    </q-form>
  </div>
</template>

<script>
import DropdownCellView from '@/core_controls/GipamCells/GipamDropdownCellView.vue'
import EventBus from '@/eventBus.js'
import axios from 'axios'

export default {
  name: 'BasicDataForm',
  components: {
    DropdownCellView,
  },
  data() {
    return {
      assetId: '',
      jcaId: '',
      assetName: '',
      isAssetCreated: false,
      dateSubmission: '',
      responsiblePersons: [],
      userNames: [],
      orphanDrugContent: null,
      yesNoOptions: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
      ],
    }
  },
  mounted() {
    this.fetchComponentDatas()
  },

  methods: {
    async fetchComponentDatas() {
      try {
        // Wait for the assetId to be fetched
        await this.fetchAssetId()
        // Wait for the assetName to be fetched
        await this.fetchAssetName()
        // Wait for the userNames to be fetched
        await this.fetchUserNames()

        // Now that assetId is available, fetch basic data
        const backendData = await this.fetchBasicDatas()
        this.setInitialResponsiblePersons(backendData)
        this.populateFormFields(backendData)
      } catch (error) {
        console.error('Error fetching component data:', error)
      }
    },
    async fetchAssetId() {
      const response = await axios.get('jca/selectedAssetId')
      this.assetId = response.data
    },
    async fetchAssetName() {
      const response = await axios.get('jca/getSelectedAssetName')
      this.assetName = response.data
    },
    async fetchUserNames() {
      const response = await axios.get('jca/getUserNames')
      this.userNames = response.data
    },
    async fetchBasicDatas() {
      console.log('this.assetId: ', this.assetId)
      const response = await axios.get(
        `jca/getBasicDatasForAsset/${this.assetId}`,
      )
      return response.data
    },
    async populateFormFields(backendData) {
      if (!backendData) {
        return
      }
      try {
        // Populate fields with backend data
        this.assetName = backendData?.assetName || this.assetName
        this.jcaId = backendData.jcaId
        const dateSubmissionObj = new Date(backendData.dateSubmission)
        const year = dateSubmissionObj.getFullYear()
        const month = String(dateSubmissionObj.getMonth() + 1).padStart(2, '0')
        this.dateSubmission = `${year}-${month}`
        this.orphanDrugContent =
          backendData.orphanDrug === 1
            ? 'Yes'
            : backendData.orphanDrug === 0
              ? 'No'
              : null

        // Reset responsiblePersons
        this.responsiblePersons = []
        backendData.responsiblePersons.forEach((resp) => {
          const user = this.userNames.find((user) => user.id === resp.userId)
          if (user) {
            this.responsiblePersons.push({
              user,
              userRole: resp.userRole || 'Other',
            })
          }
        })
      } catch (err) {
        console.log(err.response)
      }
    },
    resetFormFields() {
      // Reset all form values to their initial state
      this.jcaId = ''
      this.dateSubmission = ''
      this.orphanDrugContent = null
      this.responsiblePersons = []
      this.isAssetCreated = false // Reset this if needed
    },
    handleOrphanDrugModelValueUpdate(value) {
      this.orphanDrugContent = value
    },
    addResponsiblePerson() {
      this.responsiblePersons.push({
        user: null,
        userRole: 'Other',
      })
    },

    async setInitialResponsiblePersons(backendData) {
      if (backendData) {
        return
      }
      // Reset all values
      this.resetFormFields()
      // Ensure at least one responsible person is set
      if (this.responsiblePersons.length === 0) {
        await this.setCurrentUserToMainResponsiblePersons()
      }
      return // Exit the method early
    },
    setMainResponsible(index) {
      this.responsiblePersons = this.responsiblePersons.map((person, i) => {
        person.userRole = i === index ? 'Main Responsible' : 'Other'
        return person
      })
    },
    removeResponsiblePerson(index) {
      this.responsiblePersons.splice(index, 1)
    },
    async setCurrentUserToMainResponsiblePersons() {
      const currentUserResponse = await axios.get('/jca/getUserNameForUserId')
      this.responsiblePersons.push({
        user: {
          id: currentUserResponse.data.id,
          name: currentUserResponse.data.name,
          vorname: currentUserResponse.data.vorname,
        },
        userRole: 'Main Responsible',
      })
    },
    handleUserSelection(value, index) {
      this.responsiblePersons[index].user = value
    },
    async confirmBasicData() {
      const [year, month] = this.dateSubmission.split('-')
      const submissionDateObject = new Date(
        parseInt(year),
        parseInt(month) - 1,
        1,
      )

      const data = {
        assetId: this.assetId,
        jcaId: this.jcaId || -1,
        assetName: this.assetName,
        dateSubmission: submissionDateObject.toISOString(),
        orphanDrug:
          this.orphanDrugContent === 'Yes'
            ? 1
            : this.orphanDrugContent === 'No'
              ? 0
              : null,
        responsiblePersons: this.responsiblePersons.map((person) => ({
          userId: person.user ? person.user.id : null,
          userRole: person.userRole,
        })),
      }
      const response = await axios.post('jca/saveBasicDatas', data)
      this.jcaId = response.data // Update local state
      EventBus.emit('asset-tab-navigation', 'pico-per-country')
    },
  },
}
</script>

<style scoped>
.flex-container {
  display: flex;
  gap: 16px; /* Gap between the inputs */
}

.input-container {
  flex: 1; /* Allow inputs to grow equally */
}

.input-container label {
  display: block; /* Ensure label is on top of the input */
  margin-bottom: 4px; /* Space between label and input */
}

.button-group {
  display: flex;
  gap: 8px; /* Space between buttons */
  margin-bottom: 8px; /* Space below the button group */
}

/* Float style for the button */
.float-left {
  width: 50%; /* Set width to 50% */
  float: left; /* Float to the right */
  margin-top: 16px; /* Optional: add some space above */
}
</style>
