<template>
  <div>
    <h3>Results per Outcome</h3>
    <div class="dropdown-container">
      <select
        id="country-dropdown"
        v-model="currentTypeOfEvidence"
        class="neumorphic-select"
      >
        <option value="" disabled>Select a type</option>
        <option v-for="type in typeOfEvidence" :key="type" :value="type">
          {{ type }}
        </option>
      </select>

      <q-btn
        @click="selectOtherResults"
        class="add-new-btn neumorphic-btn"
        label="Select Results from Other Outputs"
        color="primary"
      />
    </div>

    <div>
      <label for="studyName">Name of Study:</label>
      <input type="text" v-model="studyName" placeholder="Enter study name" />
    </div>

    <div>
      <label for="relativeEffectSize"
        >Value for Relative Effect Size (Point Estimate):</label
      >
      <input
        type="number"
        v-model="relativeEffectSize"
        placeholder="Enter point estimate"
      />
    </div>

    <div>
      <label for="pValue">Significance of Point Estimate (p-value):</label>
      <input
        type="number"
        step="0.001"
        v-model="pValue"
        placeholder="Enter p-value"
      />
    </div>

    <div>
      <label for="confidenceInterval"
        >95% Confidence Interval of the Point Estimate:</label
      >
      <input
        type="text"
        v-model="confidenceInterval"
        placeholder="Enter CI (e.g., 1.5 to 2.5)"
      />
    </div>

    <div>
      <label for="treatmentEffectIntervention"
        >Absolute Value for Treatment Effect of Our Asset (Intervention):</label
      >
      <input
        type="number"
        v-model="treatmentEffectIntervention"
        placeholder="Enter treatment effect"
      />
    </div>

    <div>
      <label for="treatmentEffectComparator"
        >Absolute Value for Treatment Effect of Comparator (Control
        Group):</label
      >
      <input
        type="number"
        v-model="treatmentEffectComparator"
        placeholder="Enter treatment effect"
      />
    </div>

    <!-- Data Finality -->
    <div>
      <label>Are the data final or interim?</label>
      <div>
        <label>
          <input type="radio" v-model="dataFinality" value="final" /> Yes, final
        </label>
        <label>
          <input type="radio" v-model="dataFinality" value="interim" /> No, only
          interim results
        </label>
      </div>
    </div>

    <q-btn
      @click="showConfirmAddResult"
      class="add-new-btn neumorphic-btn"
      label="Submit Results"
      color="primary"
    />
    <q-btn
      @click="submitResults"
      class="add-new-btn neumorphic-btn"
      label="Upload Study"
      color="primary"
    />

    <!-- Modal for confirming to add new result -->
    <ConfirmAddResult
      v-if="isConfirmAddResultOpen"
      @close="closeConfirmAddResult"
      @confirm="handleConfirmAddResult"
    />

    <SelectOtherResults
      v-if="isDummyComponentOpen"
      @close="closeDummyComponent"
    />
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import ConfirmAddResult from './ConfirmAddResult.vue' // Import the new modal
import SelectOtherResults from './SelectOtherResults.vue'

export default {
  name: 'ResultsForm',
  components: {
    SelectOtherResults,
    ConfirmAddResult,
  },
  data() {
    return {
      studyName: '',
      relativeEffectSize: null,
      pValue: null,
      confidenceInterval: '',
      treatmentEffectIntervention: null,
      treatmentEffectComparator: null,
      currentTypeOfEvidence: '',
      dataFinality: '',
      isDummyComponentOpen: false, // Manage visibility of the dummy component
      isConfirmAddResultOpen: false, // Manage visibility of the confirmation modal
      typeOfEvidence: [
        'Randomized controlled trial (RCT)',
        'Subgroup analysis of RCT data',
        'Bayesian network meta-analysis (NMA)',
        'Standard NMA (e.g. Bucher’s adjusted indirect comparison)',
        'Patient-level adjustment to external controls (e.g.: by using propensity scores)',
        'Population-level adjustment methods: (e.g.: MAIC or STC)',
        'Naïve/unadjusted comparison',
        'Other (please specify)',
      ],
    }
  },
  methods: {
    openDummyComponent() {
      this.isDummyComponentOpen = true // Open the dummy component
    },
    closeDummyComponent() {
      this.isDummyComponentOpen = false // Close the dummy component
    },
    showConfirmAddResult() {
      if (!this.validateInputs()) {
        return // Stop submission if validation fails
      }
      this.isConfirmAddResultOpen = true // Open the confirmation modal
    },
    closeConfirmAddResult() {
      EventBus.emit('submit-study-results-clicked')
      this.isConfirmAddResultOpen = false // Close the confirmation modal
    },
    handleConfirmAddResult() {
      EventBus.emit('addStudyClicked')
      this.isConfirmAddResultOpen = false
      // Logic to add a new result
      console.log('User chose to add a new result')
      // You can implement your logic to handle adding a new result here
    },
    validateInputs() {
      if (
        !this.currentTypeOfEvidence ||
        !this.studyName ||
        this.relativeEffectSize === null
      ) {
        alert(
          'Please fill in the recommended fields: Type of Evidence, Name of Study, and Relative Effect Size.',
        )
        return false
      }
      return true
    },
    submitResults() {
      if (!this.validateInputs()) {
        return // Stop submission if validation fails
      }

      // Handle form submission logic here
      const results = {
        studyName: this.studyName,
        relativeEffectSize: this.relativeEffectSize,
        pValue: this.pValue,
        confidenceInterval: this.confidenceInterval,
        treatmentEffectIntervention: this.treatmentEffectIntervention,
        treatmentEffectComparator: this.treatmentEffectComparator,
      }
      console.log('Submitted Results:', results)
    },
    selectOtherResults() {
      this.isDummyComponentOpen = true // Open the modal
      console.log('Select results from other outputs clicked')
    },
  },
}
</script>

<style scoped>
div {
  margin-bottom: 15px;
}
input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}
label {
  display: block;
  margin-bottom: 5px;
}
button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
.dropdown-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
}
.neumorphic-select {
  flex: 1; /* Allow the select to take available space */
  margin-right: 10px; /* Add space between the select and button */
}
</style>
