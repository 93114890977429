<template>
  <a
    v-if="content"
    :href="content"
    target="_blank"
    :isMandatory="isMandatory"
    @click.prevent="handleClick"
  >
    Go to documents
  </a>
</template>

<script>
export default {
  name: 'LinkCell',
  props: {
    content: {
      type: String,
      required: true,
    },
    isMandatory: {
      type: Boolean,
    },
  },
  methods: {
    handleClick() {
      this.$emit('link-clicked')
    },
  },
}
</script>
