<template>
  <div class="country-selector-container">
    <div class="country-selector">
      <label for="country-dropdown">Type of Outcome:</label>
    </div>
    <select
      id="country-dropdown"
      v-model="selectedOutcome"
      @change="onOutcomeChange"
      class="neumorphic-select"
    >
      <option value="" disabled>Select an outcome</option>
      <option v-for="outcome in outcomes" :key="outcome" :value="outcome">
        {{ outcome }}
      </option>
    </select>
  </div>
  <OutcomesMasterView v-if="showOutcomes" />
</template>

<script>
import EventBus from '@/eventBus.js'
import OutcomesMasterView from './OutcomesMasterView.vue'

export default {
  components: { OutcomesMasterView },
  name: 'CountrySelectorComponent',

  data() {
    return {
      outcomes: [],
      showOutcomes: false,
      selectedOutcome: null, // Store the selected outcome here
      currentTypeOfEvidence: null,
      selectedMergedPicoId: null,
    }
  },
  async mounted() {
    await this.fetchSelectedMergedPicoIds()
    await this.fetchOutcomes()
    EventBus.on('addStudyClicked', this.addStudyClicked)
  },

  methods: {
    addStudyClicked() {
      this.showOutcomes = false
    },
    async fetchSelectedMergedPicoIds() {
      try {
        // const mergedPicoIdsResponse = await axios.get('jca/getMergedPicoIds')
        // this.mergedPicoIds = mergedPicoIdsResponse.data
      } catch (error) {
        console.error('Error fetching merged pico ids:', error)
      }
    },
    async fetchOutcomes() {
      try {
        // const outcomesResponse = await axios.get(
        //   `jca/getOutcomesForPicoId/${this.selectedMergedPicoId}`,
        // )
        // console.log('Outcomes:', outcomesResponse.data)
        // this.outcomes = outcomesResponse.data
        this.outcomes = ['Outcome 1', 'Outcome 2', 'Outcome 3']
      } catch (error) {
        console.error('Error fetching outcomes:', error)
      }
    },
    onOutcomeChange() {
      console.log('Selected Outcome:', this.selectedOutcome)
      // Here you can add any additional logic based on the selected outcome
      this.showOutcomes = true // Example: show the OutcomesMasterView based on selection
    },
  },
  beforeUnmount() {
    EventBus.off('addStudyClicked', this.addStudyClicked)
  },
}
</script>

<style scoped>
@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 800px;
  }
}
</style>
