<template>
  <div class="jca-module">
    <div class="text-primary text-h4 mb-4 text-center">My Assets</div>
    <div class="select-container mb-4 justify-center">
      <AssetSelector />
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        @click="addAssetDialog = true"
      >
        Add New Asset
      </q-btn>
    </div>

    <AddAssetDialog
      :dialogVisible="addAssetDialog"
      @close="addAssetDialog = false"
    />
    <AssetOverview v-if="asssetSelected" />
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import AddAssetDialog from './AddAssetDialog.vue'
import AssetOverview from './AssetOverview.vue'
import AssetSelector from './AssetSelector.vue'

export default {
  name: 'AssetMasterView',
  components: {
    AssetOverview,
    AssetSelector,
    AddAssetDialog,
  },
  data() {
    return {
      addAssetDialog: false,
      asssetSelected: false,
    }
  },
  methods: {
    setAssetSelected() {
      this.asssetSelected = true
    },
  },
  mounted() {
    EventBus.on('asset-selected', this.setAssetSelected) // Set up the listener
  },
  beforeUnmount() {
    EventBus.off('asset-selected', this.setAssetSelected) // Clean up the listener
  },
}
</script>

<style lang="scss">
@import '../jcaStyle.scss';
</style>
