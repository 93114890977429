<template>
  <div class="modal" v-if="isVisible">
    <div class="modal-content">
      <div class="modal-header">{{ title }}</div>
      <p class="modal-message">{{ message }}</p>
      <div class="button-container">
        <q-btn
          @click="onConfirm"
          color="primary"
          class="add-new-btn neumorphic-btn"
        >
          {{ 'Yes, specify another PICO for this country' }}
        </q-btn>
        <q-btn
          color="primary"
          class="add-new-btn neumorphic-btn"
          @click="onAlternative"
        >
          {{ 'Yes, specify another PICO for another country' }}
        </q-btn>
        <q-btn
          color="primary"
          class="add-new-btn neumorphic-btn"
          @click="onCancel"
        >
          {{ 'No, I am done specifying PICOs' }}
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
export default {
  props: {
    isVisible: Boolean,
    title: String,
    message: String,
  },
  methods: {
    onConfirm() {
      EventBus.emit('start-new-pico')
    },
    onAlternative() {
      EventBus.emit('new-pico-for-another-country')
    },
    onCancel() {
      EventBus.emit('cancel-pico')
    },
  },
}
</script>

<style>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px; /* Set a fixed width for better alignment */
}

.modal-header {
  text-align: center; /* Center the header */
  font-size: 2.5em; /* Increase font size */
  margin-bottom: 15px; /* Add space below the header */
}

.modal-message {
  font-size: 1.5em; /* Increase the message font size */
  margin-bottom: 15px; /* Add space below the message */
}

.button-container {
  display: flex;
  flex-direction: row; /* Display buttons next to each other */
  gap: 10px; /* Add space between buttons */
}
</style>
