<template>
  <div class="popup-overlay" v-if="isVisible">
    <div class="popup-content">
      <h5>Evidence Details</h5>
      <button @click="closePopup">Close</button>
      <table>
        <thead>
          <tr>
            <th>Outcome Measure</th>
            <th>Effect Size Measure</th>
            <th>Point Estimate</th>
            <th>Statistical Inference</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Outcome 1</td>
            <td>Mean difference</td>
            <td>2.5</td>
            <td>p-value 0.05 [95% CI: 1.5 - 3.5]</td>
          </tr>
          <tr>
            <td>Outcome 2</td>
            <td>OR</td>
            <td>1.8</td>
            <td>p-value = 0.03 [95% CI: 1.1 - 2.9]</td>
          </tr>
          <template v-if="false">
            <!-- Adjust as necessary for visibility -->
            <tr>
              <td>Mortality</td>
              <td>Missing data</td>
              <td>Missing data</td>
              <td>Missing data</td>
            </tr>
            <tr>
              <td>Morbidity</td>
              <td>Missing data</td>
              <td>Missing data</td>
              <td>Missing data</td>
            </tr>
            <tr>
              <td>HrQoL</td>
              <td>Missing data</td>
              <td>Missing data</td>
              <td>Missing data</td>
            </tr>
            <tr>
              <td>Safety</td>
              <td>Missing data</td>
              <td>Missing data</td>
              <td>Missing data</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EvidenceDetailsPopup',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closePopup() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 600px; /* Adjust width as needed */
}

button {
  margin-bottom: 10px;
}
</style>
