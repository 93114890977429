<template>
  <div class="pico-form neumorphic-container">
    <q-tabs v-model="activeTab" dense>
      <q-tab name="outcomes" label="Select Outcomes" />
      <q-tab name="results" label="Select Results per outcome" />
    </q-tabs>

    <q-tab-panels v-model="activeTab">
      <q-tab-panel name="outcomes">
        <Outcome />
      </q-tab-panel>

      <q-tab-panel name="results">
        <OutcomeResults />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import Outcome from './Outcome.vue'
import OutcomeResults from './OutcomeResults.vue'

export default {
  name: 'PICOForm',
  components: { Outcome, OutcomeResults },
  data() {
    return {
      activeTab: 'outcomes',
    }
  },
}
</script>
