<template>
  <div class="neumorphic-dropdown-country-selector">
    <CountrySelectorComponent />

    <PICOForm v-if="picoId && picoId != -1" />
    <ConfirmationModal
      :isVisible="showConfirmationModal"
      title="New Country selected"
      message="Would you cancel your current PICO and start a new one?"
    />
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import axios from 'axios'
import ConfirmationModal from '../ConfirmationModal.vue'
import PICOForm from '../PICOForm.vue'
import CountrySelectorComponent from './CountrySelector.vue'

export default {
  name: 'CountrySelector',
  components: { PICOForm, CountrySelectorComponent, ConfirmationModal },
  data() {
    return {
      currentPicoCountry: null,
      picoId: -1,
      showConfirmationModal: false,
    }
  },
  mounted() {
    EventBus.on('new-pico-selected', this.handleSelectedPicoId)
    EventBus.on('show-confirmation-modal', this.showConfirmationModalFunction)
    EventBus.on(
      'new-pico-for-another-country',
      this.handleSpecifyAnotherPICOForAntoherCountry,
    )
    EventBus.on('start-new-pico', this.handleSpecifyAnotherPICO)
    EventBus.on('cancel-pico', this.handleNoSpecifyAnotherPICO)
  },

  methods: {
    handleSelectedPicoId() {
      axios.get('jca/selectedPicoId').then((response) => {
        this.picoId = response.data
      })
    },
    handleSpecifyAnotherPICOForAntoherCountry() {
      this.picoId = null
      this.showConfirmationModal = false
      EventBus.emit('current-pico-country', null)
      EventBus.emit('show-pico-management-actions-buttons', true)
    },
    handleSpecifyAnotherPICO() {
      this.picoId = -1
      this.showConfirmationModal = false
      EventBus.emit('show-pico-management-actions-buttons', true)
    },
    handleNoSpecifyAnotherPICO() {
      this.showConfirmationModal = false
      EventBus.emit('asset-tab-navigation', 'pico-consolidation')
    },
    showConfirmationModalFunction() {
      this.showConfirmationModal = true
    },
  },
  beforeUnmount() {
    EventBus.off('new-pico-selected')
    EventBus.off('show-confirmation-modal', this.showConfirmationModalFunction)
    EventBus.off(
      'new-pico-for-another-country',
      this.handleSpecifyAnotherPICOForAntoherCountry,
    )
    EventBus.off('start-new-pico', this.handleSpecifyAnotherPICO)
    EventBus.off('cancel-pico', this.handleNoSpecifyAnotherPICO)
  },
}
</script>
