<template>
  <div class="popup">
    <div class="popup-content">
      <div class="centered-content">
        <div
          :class="['traffic-light', getTrafficLightStatus(missingCategories)]"
        >
          &#9679;
        </div>
        <h3>{{ popupTitle }}</h3>
        <p>{{ popupMessage }}</p>
      </div>
      <ul v-if="missingCategories.length">
        <li v-for="category in missingCategories" :key="category">
          {{ category }}
        </li>
      </ul>
      <q-btn
        @click="closePopup"
        class="add-new-btn neumorphic-btn"
        label="Close"
        color="primary"
      />
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
export default {
  name: 'EvidenceCheckPopup',
  props: {
    popupVisible: {
      type: Boolean,
      required: true,
    },
    outcomes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      popupTitle: '',
      popupMessage: '',
      missingCategories: [],
      evidenceCategories: ['Mortality', 'Morbidity', 'HrQoL', 'Safety'],
      availableEvidence: ['Mortality', 'Safety'],
    }
  },
  mounted() {
    this.checkEvidenceSupport() // Call method to check evidence when mounted
  },
  methods: {
    checkEvidenceSupport() {
      // Determine which categories are missing
      const missing = this.evidenceCategories.filter(
        (category) => !this.availableEvidence.includes(category),
      )

      // Set the popup message based on the evidence check
      if (missing.length === 0) {
        this.popupTitle = 'Evidence Support'
        this.popupMessage = 'PICO fully supported by evidence'
      } else {
        this.popupTitle = 'Evidence Support'
        this.popupMessage = 'PICO partly supported by evidence'
        this.missingCategories = missing
      }
    },
    closePopup() {
      EventBus.emit('close-evidence-popup')
    },
    getTrafficLightStatus(missing) {
      const requiredCategories = ['Mortality', 'Morbidity', 'HrQoL', 'Safety']
      const missingOutcomes = requiredCategories.filter(
        (category) => !missing.includes(category),
      )

      if (missingOutcomes.length === 0) {
        return 'green' // Fully supported
      } else if (missingOutcomes.length < requiredCategories.length) {
        return 'yellow' // Partly supported
      } else {
        return 'red' // Not supported
      }
    },
  },
}
</script>

<style scoped>
.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.traffic-light {
  font-size: 48px;
  cursor: pointer;
  margin-bottom: 10px;
}
.green {
  color: green;
}
.yellow {
  color: yellow;
}
.red {
  color: red;
}
</style>
