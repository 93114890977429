<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <h5>Confirm Submission</h5>
      <p>Do you want to add a new result to an outcome?</p>
      <div class="button-group">
        <q-btn @click="confirm" label="Yes" color="primary" />
        <q-btn @click="$emit('close')" label="No" color="primary" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmAddResult',
  methods: {
    confirm() {
      this.$emit('confirm') // Emit a confirm event
    },
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px; /* You can adjust the width as needed */
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
