<template>
  <div class="select-container mb-4 justify-center">
    <q-select
      v-model="selectedAsset"
      :options="assets"
      style="max-width: 300px; width: 100%"
      neumorphic
      dense
      label="Select Asset"
      @update:model-value="handleModelValueUpdate"
      class="q-mr-md custom-dropdown"
      placeholder="Select an Asset"
    />
  </div>
</template>

<script>
import EventBus from '@/eventBus.js' // Default import
import axios from 'axios'

export default {
  name: 'AssetSelector',
  props: {
    showAddAssetDialog: Function,
  },
  data() {
    return {
      assets: [],
      selectedAsset: null, // Initialize selectedAsset
    }
  },
  async mounted() {
    try {
      const response = await axios.get('jca/getAssets')
      this.assets = response.data.map((asset) => ({
        value: asset,
        label: asset.assetName,
      }))
    } catch (error) {
      console.error('Error fetching assets:', error)
    }

    // Set up event listener
    EventBus.on('asset-added', this.fetchSelectedAsset) // Correctly reference the function
  },
  methods: {
    async handleModelValueUpdate(value) {
      await axios.post(`/jca/setSelectedAsset/${value.value.id}`)
      EventBus.emit('asset-selected') // Emit the event
    },
    async fetchSelectedAsset() {
      try {
        const response = await axios.get('jca/getSelectedAssetName')
        this.selectedAsset = response.data // Update selected asset
      } catch (error) {
        console.error('Error fetching selected asset:', error)
      }
    },
  },
  beforeUnmount() {
    EventBus.off('asset-added', this.fetchSelectedAsset) // Clean up the listener
  },
}
</script>

<style scoped>
/* Add styles specific to the AssetSelector */
</style>
