<template>
  <div>
    <div
      v-if="picoConsolidation && picoConsolidation.length > 0"
      class="output"
    >
      <h4>PICO Evidence Library</h4>

      <!-- Filter Input for Population -->
      <div>
        <q-input
          v-model="populationFilter"
          label="Filter by Population"
          debounce="300"
          @input="filterTable"
        />
      </div>

      <q-table
        :rows="filteredPicoConsolidation"
        :columns="columns"
        row-key="picoNumber"
        virtual-scroll
        :virtual-scroll-sticky-size-start="48"
      >
        <template v-slot:body-cell-outcomes="props">
          <q-td :props="props">
            <ul>
              <li v-for="outcome in props.value" :key="outcome">
                {{ outcome }}
              </li>
            </ul>
          </q-td>
        </template>
        <template v-slot:body-cell-status="props">
          <q-td :props="props" @click="openEvidenceCheckPopup()">
            <span
              :class="[
                'traffic-light',
                getTrafficLightStatus(props.row.outcomes),
              ]"
            >
              &#9679;
            </span>
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              color="primary"
              class="add-new-btn neumorphic-btn"
              @click="addStudyClicked(props.row)"
              label="Add / revise study to Evidence Library"
            />
          </q-td>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import axios from 'axios'

export default {
  name: 'EvidenceLibrary',
  data() {
    return {
      assetId: '',
      picoConsolidation: [],
      populationFilter: '',
      showEvidenceType: false,
      popupTitle: '',
      popupMessage: '',
      missingCategories: [],
      columns: [
        {
          name: 'picoNumber',
          label: 'PICO No.',
          field: 'picoNumber',
          align: 'left',
        },
        {
          name: 'population',
          label: 'Population',
          field: 'population',
          align: 'left',
        },
        {
          name: 'intervention',
          label: 'Intervention',
          field: 'intervention',
          align: 'left',
        },
        {
          name: 'comparator',
          label: 'Comparator',
          field: 'comparator',
          align: 'left',
        },
        {
          name: 'outcomes',
          label: 'Outcome(s)',
          field: 'outcomes',
          align: 'left',
        },
        {
          name: 'status',
          label: 'Status',
          align: 'center',
          field: 'status', // Use outcomes field to compute status
        },
        {
          name: 'actions',
          label: 'Actions',
          align: 'left',
        },
      ],
    }
  },
  computed: {
    filteredPicoConsolidation() {
      return this.picoConsolidation
    },
  },
  async mounted() {
    await this.loadAssetId()
    await this.generatePICOConsolidation()
  },
  methods: {
    openEvidenceCheckPopup() {
      EventBus.emit('submit-study-results-clicked')
    },

    async addStudyClicked(selectedRow) {
      if (!selectedRow.mergedIds || selectedRow.mergedIds.length === 0) {
        alert('No IDs available for this PICO.')
        return
      }

      try {
        await axios.post('jca/setSelectedMergedIds', selectedRow.mergedIds)
        EventBus.emit('addStudyClicked')
      } catch (error) {
        console.error('Error sending selected IDs:', error)
        // Optionally handle error (e.g., show an error notification)
      }
    },
    async loadAssetId() {
      const response = await axios.get('jca/selectedAssetId')
      this.assetId = response.data
    },

    // ToDo: Move to Backend
    getTrafficLightStatus(outcomes) {
      const requiredCategories = ['Mortality', 'Morbidity', 'HrQoL', 'Safety']
      const missing = requiredCategories.filter(
        (category) => !outcomes.includes(category),
      )

      if (missing.length === 0) {
        return 'green' // Fully supported
      } else if (missing.length < requiredCategories.length) {
        return 'yellow' // Partly supported
      } else {
        return 'red' // Not supported
      }
    },

    // ToDo: Move to Backend
    async generatePICOConsolidation() {
      var picos = []
      try {
        const picoResponse = await axios.get(`jca/fetchPicos/${this.assetId}`)
        picos = picoResponse.data
        const groupedByCombinations = picos.reduce((acc, curr) => {
          const vizId = curr.visualizationId

          if (!acc[vizId]) {
            acc[vizId] = {
              combinations: [],
              intervention: curr.intervention,
              outcomes: curr.outcomes,
              country: curr.country,
              population: curr.population,
              description: curr.description,
              multipleComparatorId: curr.multipleComparatorId,
              assetId: curr.assetId,
              id: curr.id,
              visualizationId: curr.visualizationId,
            }
          }
          acc[vizId].combinations.push(curr.combinations)
          return acc
        }, {})

        // Merge PICOs based on the specified rules
        const mergedPicos = this.mergePicos(
          Object.values(groupedByCombinations),
        )
        // Transform the merged data to the desired structure
        this.picoConsolidation = await Promise.all(
          mergedPicos.map(async (pico, index) => ({
            picoNumber: index + 1,
            population: pico.population,
            intervention: pico.intervention,
            comparator: pico.combinations.join(', '),
            outcomes: pico.outcomes,
            relevantCountries: pico.relevantCountries,
            mergedIds: pico.mergedIds,
          })),
        )
      } catch (error) {
        console.error('Error fetching PICOs:', error)
      }
    },
    // ToDo: Move to Backend
    mergePicos(picos) {
      const merged = {}
      const combinationCounts = {}
      const combinationCountries = {}

      // Count occurrences of each combination and track countries
      picos.forEach((pico) => {
        pico.combinations.forEach((combination) => {
          const individualCombinations = combination
            .split(',')
            .map((c) => c.trim())

          individualCombinations.forEach((individualCombination) => {
            // Create a unique key for counting based on population, intervention, and individual combination
            const countKey = `${pico.population}|${pico.intervention}|${individualCombination}`
            combinationCounts[countKey] = (combinationCounts[countKey] || 0) + 1

            // Create a unique key based on population, intervention, and individual combination for countries
            const countryKey = `${pico.population}|${pico.intervention}|${individualCombination}`
            if (!combinationCountries[countryKey]) {
              combinationCountries[countryKey] = new Set()
            }

            // Add the country to the set for the specific key
            combinationCountries[countryKey].add(pico.country)
          })
        })
      })

      picos.forEach((pico) => {
        let processedCombinations = [
          ...pico.combinations[0].split(',').map((c) => c.trim()),
        ]

        if (pico.multipleComparatorId === 0) {
          // Keep the most frequent combination based on combinationCounts
          const mostFrequent = processedCombinations.reduce((prev, curr) => {
            const prevCount =
              combinationCounts[
                `${pico.population}|${pico.intervention}|${prev}`
              ] || 0
            const currCount =
              combinationCounts[
                `${pico.population}|${pico.intervention}|${curr}`
              ] || 0

            return currCount > prevCount ? curr : prev
          })

          processedCombinations = [mostFrequent]
          // Create a unique key for the merged pico
          const key = `${pico.population}|${pico.intervention}|${mostFrequent}`

          // Set relevantCountries from combinationCountries
          const relevantCountries = Array.from(combinationCountries[key] || [])

          if (!merged[key]) {
            // If the key doesn't exist, create a new entry
            merged[key] = {
              ...pico,
              combinations: processedCombinations,
              outcomes: pico.outcomes ? pico.outcomes.split(', ') : [],
              relevantCountries, // Set from combinationCountries
              mergedIds: [pico.id], // Store the ID of the original pico
            }
          } else {
            // If the key already exists, merge outcomes and relevant countries
            merged[key].outcomes = [
              ...new Set([
                ...merged[key].outcomes,
                ...(pico.outcomes ? pico.outcomes.split(', ') : []),
              ]),
            ]
            // Add countries from combinationCountries if not already included
            relevantCountries.forEach((country) => {
              if (!merged[key].relevantCountries.includes(country)) {
                merged[key].relevantCountries.push(country)
              }
            })
            // Add the original pico ID to the mergedIds array
            merged[key].mergedIds.push(pico.id)
          }
        } else if (pico.multipleComparatorId === 1) {
          // For each combination, create a new PICO entry
          processedCombinations.forEach((combination) => {
            const key = `${pico.population}|${pico.intervention}|${combination}`
            const relevantCountries = Array.from(
              combinationCountries[key] || [],
            )

            if (!merged[key]) {
              merged[key] = {
                ...pico,
                combinations: [combination], // Only include the current combination
                outcomes: pico.outcomes ? pico.outcomes.split(', ') : [],
                relevantCountries, // Set from combinationCountries
                mergedIds: [pico.id], // Store the ID of the original pico
              }
            } else {
              // Merge outcomes
              merged[key].outcomes = [
                ...new Set([
                  ...merged[key].outcomes,
                  ...(pico.outcomes ? pico.outcomes.split(', ') : []),
                ]),
              ]
              // Add countries from combinationCountries if not already included
              relevantCountries.forEach((country) => {
                if (!merged[key].relevantCountries.includes(country)) {
                  merged[key].relevantCountries.push(country)
                }
              })
              // Add the original pico ID to the mergedIds array
              merged[key].mergedIds.push(pico.id)
            }
          })
        }
      })

      return Object.values(merged)
    },
  },
}
</script>

<style scoped>
.output {
  margin: 20px 0;
}
.green {
  color: green;
  font-size: 24px; /* Increase size */
}
.yellow {
  color: yellow;
  font-size: 24px; /* Increase size */
}
.red {
  color: red;
  font-size: 24px; /* Increase size */
}
.traffic-light {
  font-size: 24px; /* Add this class for consistency */
  cursor: pointer; /* Change cursor to pointer */
}
</style>
