<template>
  <div class="green-cell" @click="handleClick">{{ content }}</div>
</template>

<script>
export default {
  props: {
    content: {
      type: [String, Number, Object, null],
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClick() {
      this.$emit('link-clicked')
    },
  },
}
</script>

<style>
.green-cell {
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  padding: 10px;
  word-wrap: break-word;
  background-color: #d4edda; /* Light green for RCT */
  display: block; /* Ensures the cell takes the full width */
  cursor: pointer; /* Change cursor to indicate clickable */
}
</style>
