<template>
  <div class="form-group">
    <label>Population Data:</label>
    <div class="explanatory-text">
      <p>
        Next, you will define the population(s) of interest for your study. This
        includes identifying the specific group of patients who will be the
        focus of the HTA. Consider key characteristics such as disease type,
        severity, demographic factors (age, gender), line of
        treatment/medication history, and any other relevant clinical criteria
        that align with the requirements for the targeted country.<br />
        <b>Important:</b>
        Each PICO can only include one population and one intervention.
        Additional populations can only be added once the full PICO criteria for
        the current population—including all relevant comparators and
        outcomes—has been completely specified. This ensures that each PICO is
        thoroughly defined before moving to the next patient group.
      </p>
    </div>

    <q-table
      :rows="populationData"
      :columns="columns"
      row-key="id"
      class="q-mt-md"
      style="margin-bottom: 1em"
    >
      <template v-slot:body-cell-selected="props">
        <q-td>
          <q-radio v-model="selectedPopulation" :val="props.row" />
        </q-td>
      </template>

      <template v-slot:body-cell-description="props">
        <q-td>{{ props.row.description }}</q-td>
      </template>

      <template v-slot:body-cell-disease="props">
        <q-td>{{ props.row.disease }}</q-td>
      </template>

      <template v-slot:body-cell-diseaseCharacteristics="props">
        <q-td>{{ props.row.diseaseCharacteristics }}</q-td>
      </template>

      <template v-slot:body-cell-sociodemographicRestrictions="props">
        <q-td>{{ props.row.sociodemographicRestrictions }}</q-td>
      </template>

      <template v-slot:body-cell-previousTreatments="props">
        <q-td>{{ props.row.previousTreatments }}</q-td>
      </template>

      <template v-slot:body-cell-otherCriteria="props">
        <q-td>{{ props.row.otherCriteria }}</q-td>
      </template>
    </q-table>

    <div class="form-actions">
      <div v-if="!isPopulationSelected" class="error-message">
        Please select a population before proceeding.
      </div>
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        @click="showPopulationModal = true"
        label="Add New Population"
      />

      <q-dialog v-model="showPopulationModal" @hide="clearPopulationModal">
        <PopulationModal
          :selectedOutcomeOptions="selectedOutcomeOptions"
          @close="clearPopulationModal()"
          @addPopulation="addPopulation"
        />
      </q-dialog>
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        label="Next"
        @click="emitNext"
        :disabled="!isPopulationSelected"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PopulationModal from './PopulationModal.vue'

export default {
  name: 'PopulationForm',
  assetId: null,
  picoId: null,
  components: {
    PopulationModal,
  },
  data() {
    return {
      showPopulationModal: false,
      populationData: [],
      selectedPopulation: null,
      columns: [
        {
          name: 'selected',
          label: 'Select',
          align: 'left',
          sortable: false,
        },
        {
          name: 'description',
          label: 'Description',
          align: 'left',
          field: 'description',
          sortable: true,
        },
        {
          name: 'disease',
          label: 'Disease',
          align: 'left',
          field: 'disease',
          sortable: true,
        },
        {
          name: 'diseaseCharacteristics',
          label: 'Disease Characteristics',
          align: 'left',
          field: 'diseaseCharacteristics',
          sortable: true,
        },
        {
          name: 'sociodemographicRestrictions',
          label: 'Sociodemographic Restrictions',
          align: 'left',
          field: 'sociodemographicRestrictions',
          sortable: true,
        },
        {
          name: 'previousTreatments',
          label: 'Previous Treatments',
          align: 'left',
          field: 'previousTreatments',
          sortable: true,
        },
        {
          name: 'otherCriteria',
          label: 'Other Criteria',
          align: 'left',
          field: 'otherCriteria',
          sortable: true,
        },
      ],
      populationDataForVisualizationId: [],
      isPopulationSelected: false, // New flag for population selection
    }
  },
  async mounted() {
    await this.loadAssetId()
    await this.fetchSelectedPicoId()
    await this.fetchPopulationData()
  },
  methods: {
    async emitNext() {
      if (!this.isPopulationSelected) {
        // Optionally, you can also show an alert or notification
        console.log('You must select a population before proceeding.')
        return // Prevent proceeding if no population is selected
      }

      // Create a new array to hold the modified population data
      const updatedPopulationData = this.populationData
        .map((population) => {
          // Check if the current population is the selected one
          if (population.id === this.selectedPopulation.id) {
            // Return the population with the PicoId
            return {
              ...population,
              PicoId: this.picoId, // Set the PicoId for the selected population
            }
          } else if (population.picoId === this.picoId) {
            // If this population is being deselected, set PicoId to null
            return {
              ...population,
              picoId: null,
            }
          }
          // Return null for populations that are neither selected nor deselected
          return null
        })
        // Filter out any null values
        .filter((population) => population !== null)

      // Send the updated population data to the API
      await axios.post('jca/savePopulation', updatedPopulationData)

      // Emit the navigation event
      this.$emit('navigate', 'intervention')
    },
    async addPopulation(newPopulation) {
      const existingPopulation = this.populationData.find(
        (pop) => pop.description === newPopulation.description,
      )
      if (!existingPopulation) {
        const populationWithIds = {
          ...newPopulation,
          assetId: this.assetId,
          picoId: null,
          id: -1, // Use a temporary ID
        }

        this.selectedPopulation = populationWithIds // Optionally select the newly added population
        this.showPopulationModal = false

        // Send the populationWithIds object to the backend
        const savedPopulationId = await axios.post(
          'jca/savePopulation',
          [populationWithIds], // Send the object directly
        )

        // Optionally handle the response if needed
        this.currentPopulationId = savedPopulationId.data[0]
        populationWithIds.id = this.currentPopulationId
        this.populationData.push(populationWithIds)
      } else {
        console.log('Population already exists:', existingPopulation)
      }
    },
    clearPopulationModal() {
      this.showPopulationModal = false
    },
    async setSelectedPopulation() {
      const response = await axios.get(
        `jca/getPopulationDatasForPicoId/${this.picoId}`,
      )
      if (response.data.length === 0) {
        this.selectedPopulation = null
        return
      }
      const matchingPopulation = this.populationData.find(
        (pop) => pop.id === response.data[0].id,
      )

      if (matchingPopulation) {
        this.selectedPopulation = matchingPopulation // Set selectedPopulation to the found matching object
      } else {
        this.selectedPopulation = null // Reset if no match is found
      }
    },
    async fetchPopulationData() {
      try {
        const response = await axios.get(
          `jca/getPopulationDatas/${this.assetId}`,
        )
        this.populationData = response.data.map((item) => ({
          picoId: item.picoId,
          assetId: this.assetId,
          description: item.description || '',
          disease: item.disease || '',
          diseaseCharacteristics: item.diseaseCharacteristics || '',
          sociodemographicRestrictions: item.sociodemographicRestrictions
            ? item.sociodemographicRestrictions.replace(/restrictions:\s*/i, '')
            : '',
          previousTreatments: item.previousTreatments
            ? item.previousTreatments.replace(/specify:\s*/i, '')
            : '',
          otherCriteria: item.otherCriteria
            ? item.otherCriteria.replace(/specify:\s*/i, '')
            : '',
          id: item.id,
        }))

        // Set selectedPopulation based on the population prop
        this.setSelectedPopulation()
      } catch (error) {
        console.error('Error fetching population data:', error)
      }
    },
    async loadAssetId() {
      const response = await axios.get('jca/selectedAssetId')
      this.assetId = response.data
    },
    async fetchSelectedPicoId() {
      axios.get('jca/selectedPicoId').then((response) => {
        this.picoId = response.data
      })
    },
  },
  watch: {
    selectedPopulation(newValue) {
      this.isPopulationSelected = !!newValue // Update the flag based on selection
      if (newValue) {
        this.$emit('select-population', newValue) // Emit the event with the new value
      }
    },
    population(newValue) {
      console.log('population prop changed:', newValue)
      this.setSelectedPopulation() // Call the method to set selectedPopulation
    },
  },
}
</script>

<style>
.error-message {
  color: red;
  margin-top: 10px;
}
</style>
