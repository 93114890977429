<template>
  <div class="matrix-container">
    <h5>PICO Evidence Matrix</h5>
    <GipamTable
      :rows="formattedPicoData"
      :columns="columns"
      :row-types="getRowTypes()"
      :is-mandatory="isMandatory"
      table-title="PICO Evidence Summary"
    />
  </div>
</template>

<script>
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'
import EventBus from '@/eventBus.js'

export default {
  name: 'PicoEvidenceMatrix',
  components: {
    GipamTable,
  },
  data() {
    return {
      picoData: [
        {
          name: 'PICO 1',
          countries: ['France', 'Germany'],
          evidence: {
            France: 'Randomized controlled trial (RCT)',
            Germany: 'No Evidence/Missing data',
          },
          population: 'Adults',
          intervention: 'Drug A',
          comparator: 'Placebo',
          importance: 'High',
          outcomes: [
            {
              outcome: 'Outcome 1 ganz langes Outcome',
              evidenceType: 'Randomized controlled trial (RCT)',
            },
            {
              outcome: 'Outcome 2 genauso langes outcome',
              evidenceType: 'Subgroup analysis of RCT data',
            },
          ],
          results: [
            {
              outcome: 'Outcome 1',
              effectSize: 'Mean Difference',
              pointEstimate: '5.2',
              statisticalInference: 'p < 0.05 [95% CI: 3.1 to 7.3]',
            },
            {
              outcome: 'Outcome 2',
              effectSize: 'OR',
              pointEstimate: '2.3',
              statisticalInference: 'p = 0.02 [95% CI: 1.5 to 3.5]',
            },
          ],
        },
        {
          name: 'PICO 2',
          countries: ['Italy'],
          evidence: {
            France: 'Randomized controlled trial (RCT)',
            Germany: 'No Evidence/Missing data',
            // Other countries...
          },
          population: 'Children',
          intervention: 'Drug B',
          comparator: 'Standard Care',
          outcomes: [
            {
              outcome: 'Outcome 3',
              evidenceType: 'Randomized controlled trial (RCT)',
            },
          ],
          importance: 'Medium',
          evidenceType: ['Randomized controlled trial (RCT)'],
          results: [], // Empty for no results
        },
      ],
      selectedResults: [],
      columns: [
        { label: 'PICO', field: 'name' },
        { label: 'Countries', field: 'countries' },
        { label: 'Population', field: 'population' },
        { label: 'Intervention', field: 'intervention' },
        { label: 'Comparator', field: 'comparator' },
        { label: 'Importance', field: 'importance' },
        { label: 'Outcome(s)', field: 'outcomes' },
      ],
    }
  },
  mounted() {
    EventBus.on('link-clicked', this.openPopup)
  },
  computed: {
    formattedPicoData() {
      return this.picoData.map((pico) => ({
        name: pico.name,
        countries: this.renderCountries(pico.countries),
        population: pico.population,
        intervention: pico.intervention,
        comparator: pico.comparator,
        outcomes: pico.outcomes,
        importance: pico.importance,
        evidenceType: pico.evidenceType,
      }))
    },
    isMandatory() {
      // Define if any fields are mandatory
      return this.formattedPicoData.map(() => {
        return {
          countries: true, // example, set to true if mandatory
          outcomes: true, // example, set to true if mandatory
          // Add other fields as necessary
        }
      })
    },
  },
  methods: {
    renderCountries(countries) {
      return countries.join(', ')
    },
    renderOutcomes(outcomes) {
      return outcomes.length ? outcomes.join(', ') : 'None'
    },
    getRowTypes() {
      const rowTypes = {}
      this.formattedPicoData.forEach((row, rowIndex) => {
        rowTypes[rowIndex] = {}
        this.columns.forEach((col) => {
          if (col.field === 'outcomes') {
            rowTypes[rowIndex][col.field] = 'split'
          } else {
            rowTypes[rowIndex][col.field] = 'static'
          }
        })
      })
      return rowTypes
    },
    getEvidenceClass(evidenceType) {
      switch (evidenceType) {
        case 'Randomized controlled trial (RCT)':
          return 'highlight-green'
        case 'Subgroup analysis of RCT data':
        case 'Bayesian network meta-analysis (NMA)':
        case 'Standard NMA':
          return 'highlight-light-green'
        case 'Patient-level adjustment to external controls':
        case 'Population-level adjustment methods':
          return 'highlight-yellow'
        case 'Naïve/unadjusted comparison':
          return 'highlight-orange'
        case 'Other':
        case 'No Evidence/Missing data':
          return 'highlight-red'
        default:
          return ''
      }
    },
    openPopup({ rowIndex, columnIndex }) {
      // Access the specific row based on rowIndex
      const selectedRow = this.formattedPicoData[rowIndex]

      // Depending on the columnIndex, set selectedResults
      switch (columnIndex) {
        case 0: // 'PICO'
          this.selectedResults = [{ outcome: selectedRow.name }]
          break
        case 1: // 'Countries'
          this.selectedResults = [{ outcome: selectedRow.countries }]
          break
        case 2: // 'Population'
          this.selectedResults = [{ outcome: selectedRow.population }]
          break
        case 3: // 'Intervention'
          this.selectedResults = [{ outcome: selectedRow.intervention }]
          break
        case 4: // 'Comparator'
          this.selectedResults = [{ outcome: selectedRow.comparator }]
          break
        case 5: // 'Outcome(s)'
          this.selectedResults = selectedRow.outcomes.map((outcome) => ({
            outcome: outcome,
            effectSize: 'Missing data', // Set default values for other fields
            pointEstimate: 'Missing data',
            statisticalInference: 'Missing data',
          }))
          break
        case 6: // 'Importance'
          this.selectedResults = [{ outcome: selectedRow.importance }]
          break
        case 7: // 'Type of Evidence'
          this.selectedResults = [{ outcome: selectedRow.evidenceType }]
          break
        default:
          this.selectedResults = [] // Default case if needed
          break
      }
      this.$emit('open-popup', this.selectedResults)
    },
  },
  beforeUnmount() {
    EventBus.off('cell-clicked', this.openPopup)
  },
}
</script>

<style scoped>
.matrix-container {
  margin-bottom: 20px;
}

/* You can add any additional styles specific to GipamTable here */
</style>
