<template>
  <div :class="getEvidenceTypeClass(content)">
    <span class="static-cell">{{ content }}</span>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: [String, Number, Object, null],
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    getEvidenceTypeClass(evidenceType) {
      switch (evidenceType) {
        case 'Randomized controlled trial (RCT)':
          return 'bg-green'
        case 'Subgroup analysis of RCT data':
        case 'Bayesian network meta-analysis (NMA)':
        case "Standard NMA (e.g. Bucher's adjusted indirect comparison)":
          return 'bg-light-green'
        case 'Patient-level adjustment to external controls (e.g. using propensity scores)':
        case 'Adjustment methods at population level (e.g. MAIC, STC)':
          return 'bg-yellow'
        case 'Naïve/unadjusted comparison':
        case 'Other':
          return 'bg-orange'
        case 'No Evidence/Missing data':
          return 'bg-red'
        default:
          return ''
      }
    },
  },
}
</script>

<style>
.static-cell {
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  padding: 10px;
  word-wrap: break-word;
}
/* Background color classes */
.bg-green {
  background-color: green;
  color: white; /* Optional: improve text visibility */
  padding: 10px;
  border: 1px solid #ccc; /* Optional border */
  margin-top: 5px; /* Space above collapsible content */
}
.bg-light-green {
  background-color: lightgreen;
}
.bg-yellow {
  background-color: yellow;
}
.bg-orange {
  background-color: orange;
  color: white; /* Optional: improve text visibility */
}
.bg-red {
  background-color: red;
  color: white; /* Optional: improve text visibility */
}
</style>
