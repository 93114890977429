<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <h3>Select Other Results</h3>
      <table class="outcomes-table">
        <thead>
          <tr>
            <th>Action</th>
            <th>Outcome</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="outcome in outcomes"
            :key="outcome"
            @click="selectOutcome(outcome)"
            :class="{ selected: selectedOutcome === outcome }"
          >
            <td>
              <input type="radio" :value="outcome" v-model="selectedOutcome" />
            </td>
            <td>{{ outcome }}</td>
          </tr>
        </tbody>
      </table>
      <div class="button-container">
        <q-btn @click="$emit('close')" label="Close" color="secondary" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectOtherResults',
  data() {
    return {
      outcomes: ['Outcome 1', 'Outcome 2', 'Outcome 3'],
      selectedOutcome: null,
    }
  },
  methods: {
    async fetchOutcomes() {
      try {
        // const outcomesResponse = await axios.get(
        //   `jca/getOutcomesForPicoId/${this.selectedMergedPicoId}`,
        // )
        // console.log('Outcomes:', outcomesResponse.data)
        // this.outcomes = outcomesResponse.data
        this.outcomes = ['Outcome 1', 'Outcome 2', 'Outcome 3']
      } catch (error) {
        console.error('Error fetching outcomes:', error)
      }
    },
    selectOutcome(outcome) {
      this.selectedOutcome = outcome
      console.log('Selected Outcome:', this.selectedOutcome)
      // Additional logic for when an outcome is selected can be added here
    },
  },
  mounted() {
    this.fetchOutcomes() // Fetch outcomes when component mounts
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px; /* You can adjust the width as needed */
}

.outcomes-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.outcomes-table th,
.outcomes-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.outcomes-table tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}

.outcomes-table .selected {
  background-color: #e0e0e0; /* Highlight selected row */
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Align button to the right */
}
</style>
